import React from 'react'
// import styled from 'styled-components'
import Layout from '../../components/layout'
import { MdLocationOn, MdPhone, MdMailOutline  } from "react-icons/md";
import Phone from '../../components/phoneSec/contactinfo'
import logo from '../../images/logo.png'
import Sicon from '../../components/phoneSec/iconlist'
import Image from '../../components/phoneSec/image'

import { FaFacebook, FaPinterest, FaGlobe, FaInstagram, FaWhatsapp, FaYoutube, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

export default function PhonePage(props) {


    const person = [
        {
         

             
             title:"9342232874",
             title2:"9342232874",
             ppath1:"9342232874",
             ppath2:"9342232874",
  

            icon:<MdPhone/>
        },
        {
            title:"interiors@designseries.co.in",
            icon:<MdMailOutline/>,
            ppath1:"interiors@designseries.co.in",

        },
        {
            title:"www.designseries.co.in",
            icon:<FaGlobe/>,
            ppath1:"http://www.designseries.co.in"
        },
        {
            title:"#1869, 28th Main, S End C Cross Rd, Jayanagar 9th Block East, Bengaluru, Karnataka 560069",
            icon:<MdLocationOn/> ,
            ppath1:"https://maps.app.goo.gl/k6Ex3wqqthAFr9Aw5"
        }
    ]

    let social = [{
        sicon:<FaFacebook/>,
        path: "https://www.facebook.com/designseriesofficial",
        color:"#214F97"
    },
    {
        sicon:<FaInstagram/>,
        path:"https://www.instagram.com/designseriesofficial",
        color:"#DB625B"

    },
    {
        sicon:<FaWhatsapp/>,
        path:"https://wa.me/918971181818",
        color:"#2AA138"

    },
    {
        sicon:<FaYoutube/>,
        path:"https://www.youtube.com/channel/UC_haKFLOrkCUmykIsO55OUQ?view_as=subscriber",
        color:"#ED1B0D"

    },
    {
        sicon:<FaLinkedinIn/>,
        path:"https://www.linkedin.com/company/designseriesofficial",
        color:"#0D70AA"

    },
    {
        sicon:<FaTwitter/>,
        path:" https://twitter.com/DesignSeriesHQ",
        color:"#1C9CEA"

    },
    {
        sicon:<FaPinterest/>,
        path:"https://in.pinterest.com/designseriesofficial",
        color:"#C51E26"

    }]
   
let content = `With over 3 decades of experience in the design industry, Design Series undertakes interior design for residential and office interiors. Having worked on projects that demand design and innovation on par with global standards, they are geared to offer design solutions that are modern and efficient. Design Series is a design studio that believes in creating designs that are unique and creative, while taking care of the needs of their clients. They ensure that the projects are completed in time while maintaining quality and value for money. They work with diverse individual and professionals to offer end to end solutions that help you have a stress-free experience.`

    return (
        <div style={{backgroundColor:"#000000"}}>

<Layout
 location={props.location.pathname}

  >

    <Phone person={person} 
      logo={logo}
  name="Huma Anwar"/>
    <Sicon siconlist={social}/>
    <Image content={content}/>
</Layout>
   
        </div>
    )
}


